export const recentPost = {
  "id": 12,
  "site": "matchyverse",
  "title": "Enter King Zazz",
  "category": "character",
  "subCategory": [
    "game",
    "baddie",
    "antagoinst"
  ],
  "description": "Not everyone in the Matchyverse™ is sunshine and rainbows! Entering the main baddie for this colorful universe.",
  "fullText": "<p>Not everyone in the Matchyverse™ is sunshine and rainbows! Entering the main baddie for this colorful universe.</p><p>King Zazz is just a little fireball but so much more. He lives in his lava lamp styled laboratory in the Zazz Dimension where he beams rays of light that confuse the citizens of the Matchyverse. Inside the Zazz Dimension, King Zazz can change anything, remaking even the sky to suit his mood.</p><p>But King Zazz has a lot of emotions, changing colors depending on how he currently feels. And Zazz is not alone! He has the \"Bad Actors\" a troupe of sentient robots who obey King Zazz's commands, when they feel like it of course!</p><p>Using his beams, Zazz is the king of glitches, making computers hiccup and bend to his will across the Matchyverse. Can you defeat him?</p>",
  "authorName": "Michael Silverman",
  "authorLink": "https://michaelplzno.com",
  "authorAvatar": "/assets/images/matchyverse-signature-michael.webp",
  "createdAt": "October 12, 2024",
  "cover": "/assets/images/ZazzJester.webp",
  "ctaButtons": [
    {
      "platformLogo": "/assets/images/discord.png",
      "titleText": "Join Our Discord Chat",
      "priceTag": "Learn More!",
      "appIcon": "/assets/images/MatchyOGIcon.png",
      "linkTo": "https://discord.silverwaregames.com"
    }
  ]
};